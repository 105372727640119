<template>
  <div id="userInfo">
    <!-- 搜索框 -->
    <div class="infoTop flex-center">
      <div class="icon-d" @click="goBack">
        <van-icon name="arrow-left icon-left" />
      </div>
      <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="condition.mapName"
        @keyup.enter.native="search">
      </el-input>
    </div>
    <!-- 主体内容 -->
    <div class="middle">
      <van-list v-model="loading" :finished="finished" :offset="10" finish-text="没有更多了" @load="onLoad">
        <div class="date" v-for="(value, key, index) in list" :key="index">
          <div class="botTop">
            <img src="../../assets/clock.png" alt="" class="clockImg" />
            <span>{{ key }}</span>
          </div>
          <!-- <div class="cehui" v-for="item in value" :key="item.id" @click="goMap(item)">
            <div class="top">
              <div class="tag">查看</div>
              <img src="../../assets/house.png" alt="" class="houseImg" />
              <div class="right">
                <p class="name">
                  {{item.mapName}}
                </p>
                <div>
                  <span>总面积</span>
                  <span>{{ item.grossArea }} m²</span>
                </div>
                <div>
                  <span>总装机量</span>
                  <span>{{ item.totalInstallCapacity }} kWp</span>
                </div>
                <div>
                  <span>总投资金额</span>
                  <span>{{ item.totalInvestment }} 万元</span>
                </div>
                <div>
                  <span>年发电量</span>
                  <span>{{ item.annualEnergyOutput }} 万度</span>
                </div>
              </div>
            </div>
          </div> -->
          <van-swipe-cell v-for="item in value" :key="item.id">
            <div class="cehui" @click="goMap(item)">
              <div class="top">
                <div class="tag">查看</div>
                <div class="houseImg">

                  <img :src="item.mapImg != '' ? item.mapImg : mapImg1" alt=""
                    :class=" item.mapImg != '' ? 'img1' : 'img2'" />
                </div>

                <!-- <img class="houseImg" :src="item.mapImg || '../../assets/house.png'" alt="" /> -->

                <div class="right">
                  <p class="name">
                    {{ item.mapName }}
                  </p>
                  <div>
                    <span>总面积</span>
                    <span>{{ item.grossArea }} m²</span>
                  </div>
                  <div>
                    <span>总装机量</span>
                    <span>{{ item.totalInstallCapacity }} kWp</span>
                  </div>
                  <div>
                    <span>总投资金额</span>
                    <span>{{ item.totalInvestment }} 万元</span>
                  </div>
                  <div>
                    <span>年发电量</span>
                    <span>{{ item.annualEnergyOutput }} 万度</span>
                  </div>
                </div>
              </div>
            </div>
            <template #right>
              <van-button square text="删除" type="danger" class="delete-button" @click="delectMap(item)" />
            </template>
          </van-swipe-cell>
        </div>
      </van-list>
    </div>
    <van-empty description="暂无数据" v-if="!Object.keys(list).length" />
    <!-- 底线 -->
    <div class="bottom">
      <div class="leftLine"></div>
      <p>已经到底啦</p>
      <div class="rightLine"></div>
    </div>
  </div>
</template>

<script>
import mapImg1 from '../../assets/house.png'
export default {
  data () {
    return {
      list: {}, // 请求的列表
      loading: false,
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      condition: {
        mapName: "",
      },
      currPage: 1, // 当前页码数
      pageSize: 7, // 一页显示7条
      mapImg1
    };
  },
  created () { },
  methods: {
    goMap (item) {
      //去地图页
      this.$router.push({
        path: "/home",
      });
      sessionStorage.setItem('mapCode', item.mapCode)
    },

    goBack () {
      this.$router.back();
    },

    search () {
      this.currPage = 1;
      this.getMeasureList();
    },

    // 上拉加载
    onLoad () {
      setTimeout(() => {
        this.getMeasureList();
      }, 1000);
    },

    getMeasureList () {
      this.$http
        .post("/measureMap/list", {
          condition: {
            mapName: this.condition.mapName,
            memberCode: this.$info.memberCode,
          },
          currPage: this.currPage,

          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.data.code == 200) {
            // 如果是第一次进入页面page==1 直接赋值
            if (this.currPage === 1) {
              this.list = res.data.data.dataMap;
            } else {
              const dataMap = res.data.data.dataMap;
              for (let i in this.list) {
                for (let j in dataMap) {
                  if (i == j) {
                    this.list[i] = this.list[i].concat(dataMap[j]);
                    delete dataMap[j];
                  }
                }
              }
              this.list = { ...this.list, ...res.data.data.dataMap };
              // // 追加完成后关闭loading
              this.loading = false;
            }
            // 当还有数据是page++
            if (Object.keys(res.data.data.dataMap).length > 0) {
              this.currPage++;
              this.loading = false;
            } else {
              // 如果没有数据加载完毕
              this.finished = true;
            }
          }
        });
    },
    // 删除地图
    delectMap (item) {
      console.log("111", item);
      this.$dialog
        .confirm({
          title: "温馨提示",
          message: "您确定要删除吗？",
        })
        .then(() => {
          this.$http
            .post("/measureMap/deleteByCode", {
              mapCode: item.mapCode,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$toast.success("删除成功");
                this.search()
              }
            });
        })
        .catch(() => {
          console.log("点击了取消");
        });
    },
  },
};
</script>

<style lang="less" scoped>
#userInfo {
  box-sizing: border-box;
  .icon-d {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    width: 50px;
    background: #238bf9;
    color: #fff;
    border-radius: 10px;
    margin-right: 5px;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-left {
    font-size: 20px;
    margin-right: 10px;
  }
  .infoTop {
    height: 70px;
    padding: 15px 12px 12px 12px;
    box-sizing: border-box;
    border-bottom: 1px solid #cbcbcb;
    .search {
      width: 351px;
      height: 44px;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: -1px 1px 5px #999999;
      text-align: center;
      position: relative;
      font-size: 16px;
      color: #333;
      font-weight: 500;
      .searchImg {
        width: 26px;
        height: 25px;
        position: absolute;
        top: 9px;
        left: 12px;
      }
    }
  }
  .middle {
    .date {
      width: 100%;
      padding: 8px 12px 0px 12px;
      border-bottom: 1px solid #cbcbcb;
      box-sizing: border-box;
      .botTop {
        position: relative;
        margin-bottom: 8px;
        .clockImg {
          position: absolute;
          width: 12px;
          height: 12px;
          top: 6px;
          left: 0;
        }
        span {
          font-size: 14px;
          color: #238bf9;
          margin-left: 30px;
        }
      }
      .cehui {
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 1px 1px 5px #999999;
        margin-bottom: 12px;
        box-sizing: border-box;
        .top {
          position: relative;
          height: 134px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding-left: 3px;
          overflow: hidden;
          .tag {
            position: absolute;
            top: 0;
            right: 0;
            color: #fff;
            background: #408cf1;
            width: 45px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 0 10px 0 10px;
            font-size: 14px;
          }
          .houseImg {
            display: block;
            width: 135px;
            height: 110px;
            .img1 {
              width: 70%;
              height: 105%;
              margin-left: 20%;
            }
            .img2 {
              width: 70%;
              height: 105%;
              margin-left: 15%;
            }
          }
          .right {
            width: 180px;
            div {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              color: #666666;
              margin-bottom: 4px;
            }
            .name {
              width: 100%;
              font-size: 15px;
              font-weight: 700;
              color: #333333;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .bottom {
          height: 66px;
          padding: 14px 59px 12px 59px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          .common {
            width: 24px;
            text-align: center;
            span {
              font-size: 12px;
              color: #666;
            }
          }
          .watch {
            img {
              width: 22px;
              height: 16px;
            }
          }
          .editor {
            img {
              width: 19px;
              height: 19px;
            }
          }
          .share {
            img {
              width: 21px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .bottom {
    height: 65px;
    padding: 0px 116px;
    display: flex;
    justify-content: space-between;
    position: relative;
    p {
      color: #999999;
      font-size: 10px;
      margin-top: 12px;
    }
    .leftLine {
      width: 35px;
      height: 8px;
      border-bottom: 1px solid #999999;
      margin-top: 12px;
    }
    .rightLine {
      width: 35px;
      height: 8px;
      border-bottom: 1px solid #999999;
      margin-top: 12px;
    }
  }
  .goods-card {
    margin: 0;
    background-color: #fff;
  }

  .delete-button {
    height: 100%;
  }
}
</style>