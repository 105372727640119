<template>
  <div id="welcome">
    <div class="timeout " @click="goHome">
      <span>跳过{{ count }}</span>
    </div>
    <div class="introduce">
      <div class="tltle">
        <img src="../../assets/lingxing.png" alt="" />
        <p>光伏投资宝电子围栏测绘功能介绍：</p>
      </div>
      <div class="detail one">
        <img src="../../assets/squre.png" alt="" />
        <p>
          包含两种测绘模式——粗标点及精标点模式，支持一个项目绘制多个不连续的电子围栏；
        </p>
      </div>
      <div class="detail">
        <img src="../../assets/squre.png" alt="" />
        <p>
          测绘完成可对单个电子围栏和对整个项目进行自定义命名，可单独查看计算结果，可统一查看计算结果；
          <!-- 项目详情由测绘时生成的数据自动计算得出，无需手动输入；
          可在测绘列表页将一个完整项目分享给好友，好友可看到全部详情但不可编辑分享内容； -->
        </p>
      </div>
      <div class="detail two">
        <img src="../../assets/squre.png" alt="" />
        <p>项目详情由测绘时生成的数据自动计算得出，无需手动输入；</p>
      </div>
      <div class="detail">
        <img src="../../assets/squre.png" alt="" />
        <p>
          可在测绘列表页将一个完整项目分享给好友，好友可看到全部详情但不可编辑分享内容；
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      count: '',
    };
  },
  methods: {
    countDown () {
      const TIME_COUNT = 5;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 1 && this.count <= TIME_COUNT) {
            //限制倒计时区间
            this.count--;
          } else {
            clearInterval(this.timer); //删除定时器
            this.timer = null;
            //跳转的页面写在此处
            this.$router.push("/home");
          }
        }, 1000);
      }
    },
    goHome () {
      clearInterval(this.timer);
      this.$router.push("/home");
    }
  },
  created () {
    const { lat, log, memberCode,type } = this.$route.query
    if (lat) {
      this.$info.lat = lat
    }
    if (log) {
      this.$info.log = log
    }
    if(type=='1'){
      this.goHome();
    }
    console.log(this.$info);
    this.$info.memberCode = memberCode
    this.countDown() // 倒计时
    sessionStorage.clear()
  },
};
</script>

<style lang="less" scoped>
#welcome {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/welcomeBg.png);
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  .timeout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 30px;
    font-size: 16px;
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 40px;
    right: 20px;
  }
  .introduce {
    width: 350px;
    height: 240px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    bottom: 63px;
    left: 50%;
    transform: translate(-50%);
    // margin: 0 auto;
    // margin-top: 513px;
    padding: 15px 16px 15px 13px;
    box-sizing: border-box;
    .tltle {
      display: flex;
      align-content: center;
      img {
        width: 17px;
        height: 17px;
        margin-right: 8px;
      }
      p {
        font-size: 13px;
        margin-top: -2px;
        color: #333;
      }
    }
    .detail {
      display: flex;
      img {
        width: 6px;
        height: 6px;
        margin: 3px 13px 37px 6px;
      }
      p {
        font-size: 13px;
        color: #333;
        height: auto;
      }
    }
    .one {
      margin-top: 10px;
    }
    .two {
      margin-top: 8px;
    }
  }
}
</style>