<template>
  <div id="detail">
    <!-- 搜索框 -->
    <div class="detailTop flex-center">
      <div class="icon-d" @click="goBack">
        <van-icon name="arrow-left icon-left" />
      </div>
      <div class="area">
        <label class="icon">所在地区</label>
        <div class="city">
          <p @click="showPicker = true">{{province.name}} - {{city.name}}</p>
          <img src="../../assets/position.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 主体内容 -->
    <div class="middle">
      <div class="content">
        <div class="title">
          <p>电站参数</p>
        </div>
        <div class="data">
          <div class="model">
            <div class="box">
              <p class="mandatory">建筑类型</p>
              <el-select class="input-w" v-model="roofType" @change="changeRoof">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

           
            <div class="box bbox">
              <p class="mandatory">安装面积(㎡)</p>
              <el-input v-model="area" class="input-w" disabled>
              </el-input>
            </div>
            <div class="box bbox">
              <p>装机量(kWp)</p>
              <el-input disabled v-model="totalInstallCapacity" placeholder="自动换算/可改动" class="input-w">
              </el-input>
            </div>
            <div class="box bbox">
              <p>每瓦成本(元/Wp)</p>
              <el-input type="number" v-model="defaultWp" placeholder="默认抓取/可改动" class="input-w">
              </el-input>
            </div>
            <div class="box bbox">
              <p>总投资(万元)</p>
              <!-- <el-input disabled v-model="totalInvestment" placeholder="自动换算" class="input-w">
              </el-input> -->
              <p style="color:#f34e38">{{totalInvestment}}</p>
            </div>
            <div class="box bbox">
              <p>kWh/kWp/年</p>
              <!-- <el-input disabled v-model="irradiationParam" placeholder="抓取" class="input-w">
              </el-input> -->
              <p>{{irradiationParam}}</p>
            </div>
            <div class="box bbox">
              <p>年发电量(万kWh)</p>
              <!-- <el-input disabled v-model="tAnnualEnergyOutputs" placeholder="抓取" class="input-w">
              </el-input> -->
              <p class="orange">{{tAnnualEnergyOutputs}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="title">
          <p>合作模式</p>
        </div>
        <div class="data">
          <!-- 模式A -->
          <div class="model modelA">
            <div class="buy">
              <p style="color: #f34e38;">模式A: 优惠购电</p>
              <img src="../../assets/gantan.png" alt="" @click="centerDialogVisible = true" />
            </div>
            <div class="box bbox">
              <p>用户平均电价(元/kWh)</p>
              <el-input type="number" class="input-w" v-model="userAvgEp" @input="changeEle"> </el-input>
            </div>
            <div class="box bbox">
              <p>电费折扣(%)</p>
              <el-input type="number" class="input-w" v-model="electricityPricesRate" @input="changeEle"> </el-input>
            </div>
            <div class="box bbox">
              <p>折后电费(元/kWh)</p>
              <el-input type="number" class="input-w" v-model="disElectricity" @input="changeEleDis"> </el-input>
            </div>
            <div class="box">
              <p class="green">每年节省电费(万元)</p>
              <!-- <el-input disabled class="input-w" v-model="yearSaveElectricity"> </el-input> -->
              <p class="green">{{yearSaveElectricity}}</p>
            </div>
            <div class="box box1">
              <p class="green">25年产生绿电(万度)</p>
              <p class="green">{{tfYearProduceGe}}</p>
              <!-- <el-input disabled style="width: 120px; height: 27px;" v-model="tfYearProduceGe"> </el-input> -->
            </div>
            <div class="box box1">
              <p class="green">25年累计节省(万元)</p>
              <p class="green">{{tfYearSaveEp}}</p>
              <!-- <el-input disabled style="width: 120px; height: 27px;" v-model="tfYearSaveEp"> </el-input> -->
            </div>
          </div>
          <!-- 线线线 -->
          <div class="centerLine"></div>
          <!-- 模式B -->
          <div class="model modelB">
            <div class="buy">
              <p style="color:rgb(41, 142, 236)">模式B: 自主投资</p>
              <img src="../../assets/gantan.png" alt="" @click="centerDialogVisible1 = true" />
            </div>
            <div class="box">
              <p>初装补贴(元/Wp)</p>
              <el-input type="number" class="input-w" v-model="installSubsidy"> </el-input>
            </div>
            <div class="box">
              <p>国家发电补贴(元/kWh)</p>
              <el-input type="number" class="input-w" v-model="stateSubsidyElectricity"> </el-input>
            </div>
            <div class="box">
              <p>国家补贴年限(年)</p>
              <el-input type="number" class="input-w" v-model="stateSubsidyYear"> </el-input>
            </div>
            <div class="box">
              <p>地方发电补贴(元/kWh)</p>
              <el-input type="number" class="input-w" v-model="placeSubsidyElectricity"> </el-input>
            </div>
            <div class="box">
              <p>地方补贴年限(年)</p>
              <el-input type="number" class="input-w" v-model="placeSubsidyYear"> </el-input>
            </div>
            <div class="box box2">
              <p class="orange">回本时间(年)</p>
              <p class="orange">{{backTime}}</p>
              <!-- <el-input disabled class="input-w" v-model="backTime"> </el-input> -->
            </div>
            <div class="box box2">
              <p class="orange">年均收益(万元)</p>
              <p class="orange">{{cavgAnnualEarnings}}</p>
              <!-- <el-input disabled class="input-w" v-model="cavgAnnualEarnings"> </el-input> -->
            </div>
            <div class="box box2">
              <p class="orange">年收益率/ROI(%)</p>
              <p class="orange">{{roi}}</p>
              <!-- <el-input disabled class="input-w" v-model="roi"> </el-input> -->
            </div>
            <div class="box box1">
              <p class="green">25年预计收益(万元)</p>
              <p class="green">{{tfYearExpectEarnings}}</p>
              <!-- <el-input disabled class="input-w" v-model="tfYearExpectEarnings"> </el-input> -->
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="midBot">
          <div class="reduce">减少碳排放量/吨</div>
          <div class="case">
            <p>每年节约标准煤量</p>
            <p>{{standardCoalSaveAnnual.toFixed(2)}}</p>
          </div>
          <div class="case">
            <p>每年减少碳粉尘排放量</p>
            <p style="color:#8f2660">{{carbonDustEmission.toFixed(2)}}</p>
          </div>
          <div class="case">
            <p>每年减少二氧化硫排放量</p>
            <p style="color:#fd762f">{{sulfurDioxideEmission.toFixed(2)}}</p>
          </div>
          <div class="case">
            <p>每年减少氮氧化物排放量</p>
            <p style="color:#5096ec">{{nitrogenOxidesEmission.toFixed(2)}}</p>
          </div>
          <div class="case">
            <p>每年减少二氧化碳排放量</p>
            <p>{{carbonDioxideEmission.toFixed(2)}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 底线 -->
    <div class="bottom">
      <div class="leftLine"></div>
      <p>已经到底啦</p>
      <div class="rightLine"></div>
    </div>
    <!-- 优惠购电弹窗 -->
    <el-dialog :visible.sync="centerDialogVisible" width="80%" center :custom-class="'class1'">
      <div slot="title" class="dialogTitle">优惠购电</div>
      <span style="color: #333333; font-size: 13px">屋顶业主无需投资，由资金方投资按照优惠电价缴纳太阳能电费，使用太阳能电越多节省越多</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" style="border: none; color: #238bf9">确 定</el-button>
      </span>
    </el-dialog>
    <!-- /优惠购电弹窗 -->
    <!-- 资助投资弹窗 -->
    <el-dialog :visible.sync="centerDialogVisible1" width="80%" center :custom-class="'class1'">
      <div slot="title" class="dialogTitle">自主投资</div>
      <span style="color: #333333; font-size: 13px">屋顶业主自行投资，平台给提供设计、采购，安装和整个生命周期的监控和运维服务</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible1 = false" style="border: none; color: #238bf9">确 定</el-button>
      </span>
    </el-dialog>
    <!-- /自主投资弹窗 -->

    <van-popup v-model="showPicker" round position="bottom">
      <van-area title="" :area-list="columns" columns-num='2' @confirm="choiceCity" />
    </van-popup>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  data () {
    return {
      province: {
        name: "安徽省",
        code: '340000'
      },
      city: {
        name: "合肥市",
        code: '340100'
      },
      showPicker: false,
      columns: areaList,
      options: [
        {
          value: "1",
          label: "彩钢瓦",
        },
        {
          value: "2",
          label: "水泥顶",
        },
      ],
      roofType: "build_type_one",
      centerDialogVisible: false,
      centerDialogVisible1: false,
      defaultParam: 0,
      defaultWp: 0,
      irradiationParam: 0,
      area: 0,
      userAvgEp: 0, //用户平均电价
      electricityPricesRate: 0, //电费折扣
      disElectricity: 0, //折后电费
      installSubsidy: '', //初装补贴
      stateSubsidyElectricity: '', //国家发电补贴
      stateSubsidyYear: '', //国家补贴年限
      placeSubsidyElectricity: '',//地方发电补贴
      placeSubsidyYear: '', //地方补贴年限
    };
  },

  computed: {
    //装机
    totalInstallCapacity () {
      return (this.defaultParam * this.area).toFixed(2)
    },
    //总投资
    totalInvestment () {
      return (this.totalInstallCapacity * (this.defaultWp - this.installSubsidy) / 10).toFixed(2)
    },
    //年发电量
    tAnnualEnergyOutputs () {
      return (this.totalInstallCapacity * this.irradiationParam / 10000).toFixed(2)
    },
    // //折后电费
    // disElectricity () {
    //   return (this.userAvgEp * this.electricityPricesRate / 100).toFixed(4)
    // },
    //每年节约电费
    yearSaveElectricity () {
      return ((this.userAvgEp - this.disElectricity) * this.tAnnualEnergyOutputs).toFixed(4)
    },

    //25年产生绿电
    tfYearProduceGe () {
      return (this.tAnnualEnergyOutputs * 25).toFixed(2)
    },

    //25年累计节省
    tfYearSaveEp () {
      return (this.yearSaveElectricity * 25).toFixed(2)
    },


    //回本时间
    backTime () {
      return (this.totalInvestment / this.cavgAnnualEarnings).toFixed(2)
    },
    //年均收益
    cavgAnnualEarnings () {
      return ((this.tAnnualEnergyOutputs * this.userAvgEp) + (this.tAnnualEnergyOutputs * this.placeSubsidyElectricity * this.placeSubsidyYear) / 25 + (this.tAnnualEnergyOutputs * this.stateSubsidyElectricity * this.stateSubsidyYear) / 25).toFixed(2)
    },
    //年收益率/ROI(%)
    roi () {
      return ((this.tfYearExpectEarnings - this.totalInvestment) / 25 / this.totalInvestment * 100).toFixed(2)
    },
    //25年预计收益
    tfYearExpectEarnings () {
      return (this.cavgAnnualEarnings * 25).toFixed(2)
    },



    //每年节约标准煤量
    standardCoalSaveAnnual () {
      return this.tAnnualEnergyOutputs * 3.28
    },
    //每年减少碳粉尘排放量
    carbonDustEmission () {
      return this.tAnnualEnergyOutputs * 2.72
    },
    //每年减少二氧化硫排放量
    sulfurDioxideEmission () {
      return this.tAnnualEnergyOutputs * 0.3
    },
    //每年减少氮氧化物排放量
    nitrogenOxidesEmission () {
      return this.tAnnualEnergyOutputs * 0.15
    },
    //每年减少二氧化碳排放量
    carbonDioxideEmission () {
      return this.tAnnualEnergyOutputs * 9.97
    },



  },

  created () {
    const { city, province, area } = this.$route.query
    this.area = area
    this.city = JSON.parse(city)
    this.province = JSON.parse(province)
    this.getParamsByCity()
    this.getRoofType()
  },

  methods: {

    changeEle () {
      //修改平均电价 或者电费折扣
      this.disElectricity = (this.userAvgEp * this.electricityPricesRate / 100).toFixed(4)
    },

    changeEleDis () {
      //修改 折后电费
      this.electricityPricesRate = (this.disElectricity / this.userAvgEp * 100).toFixed(2)
    },

    getRoofType () {
      //获取屋顶类型
      this.$http.post('/dict/queryList', { dictType: 'buildType' }).then(res => {
        if (res.data.code == 200) {
          // const { buildRatio, costPerWatt, rpupg, userAvgEp, electricityPricesRate } = res.data.data
          this.options = res.data.data.map(item => {
            return {
              value: item.dictValue,
              label: item.dictLabel
            }
          })
        }
      })
    },

    getParamsByCity () {
      //获取城市辐射和电价
      this.$http.post('/measureMap/queryMapConfig', { cityCode: this.city.code, provinceCode: this.province.code, buildType: this.roofType }).then(res => {
        if (res.data.code == 200) {
          const { buildRatio, costPerWatt, rpupg, userAvgEp, electricityPricesRate } = res.data.data
          this.defaultParam = buildRatio
          this.defaultWp = costPerWatt
          this.irradiationParam = rpupg
          this.userAvgEp = userAvgEp
          this.electricityPricesRate = electricityPricesRate
          this.changeEle()
        }
      })
    },

    changeRoof () {
      //切换建筑类型
      this.getParamsByCity()
    },


    choiceCity (value, index) {
      this.showPicker = false
      this.province = {
        name: value[0].name,
        code: value[0].code,
      }
      this.city = {
        name: value[1].name,
        code: value[1].code,
      }
      this.getParamsByCity()
    },


    goBack () {
      this.$router.back();
    },

  }
};
</script>

<style lang="less" scoped>
.icon-d {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 50px;
  background: #238bf9;
  color: #fff;
  border-radius: 10px;
  margin-right: 5px;
}
.icon-left {
  font-size: 20px;
}
.city {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-w {
  font-weight: 700;
  width: 100px;
  height: 25px;
}
/deep/ .el-input.is-disabled .el-input__inner {
  color: #606266;
}
#detail {
  width: 100%;
  box-sizing: border-box;
  .detailTop {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    .area {
      width: 330px;
      height: 50px;
      margin: 0 auto;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: -1px 1px 5px #999999;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      .icon {
        font-size: 20px;
        color: #000;
        margin-left: 6px;
      }
      .icon::after {
        content: ' * ';
        color: red;
      }
      p {
        font-size: 20px;
        color: #238bf9;
        font-weight: bold;
      }
      img {
        width: 12px;
        height: 16px;
        margin-left: 12px;
      }
    }
  }
  .middle {
    .content {
      width: 100%;
      padding: 0px 12px 0px 12px;
      box-sizing: border-box;
      .title {
        margin-bottom: 8px;
        p {
          color: #000;
          font-size: 20px;
          font-weight: bold;
          border-left: 4px solid #238bf9;
          padding-left: 8px;
          box-sizing: border-box;
        }
      }
      .data {
        width: 351px;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: -1px 1px 8px #999999;
        margin-bottom: 12px;
        padding: 24px 12px;
        box-sizing: border-box;
        .model {
          .buy {
            font-size: 18px;
            color: #333;
            display: flex;
            align-items: center;
            font-weight: bold;
            margin-bottom: 12px;
            img {
              width: 12px;
              height: 12px;
              margin-left: 4px;
            }
          }
          .box1 {
            /deep/ .el-input__inner {
              border-color: #00c14a;
            }
          }
          .box2 {
            /deep/ .el-input__inner {
              border-color: #fd7622;
            }
          }
          .box {
            height: 34px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 5px;
            box-sizing: border-box;
            /deep/ .el-input__inner {
              width: 100px;
              height: 26px;
              font-size: 18px;
              line-height: 26px;
              text-align: center;
              border-color: #707070;
            }
            /deep/ .el-input__icon {
              line-height: 25px;
            }
            p {
              font-size: 18px;
              font-weight: 700;
              color: #666666;
            }
            .mandatory {
              font-size: 20px;
            }
            .mandatory::after {
              content: ' * ';
              color: red;
            }
            .green {
              font-size: 20px;
              color: #00c14a;
              font-weight: bold;
            }
            .orange {
              font-size: 20px;
              color: #fd7622;
              font-weight: bold;
            }
          }
          .box:nth-child(even) {
            background-color: #e0e0e0;
          }
          .bbox {
            p {
              font-size: 20px;
            }
          }
        }
        .modelA {
          margin-top: -6px;
          // border-bottom: 1px solid #707070;
        }
        .modelB {
          margin-top: 12px;
        }
        .centerLine {
          width: 50%;
          margin: 20px auto;
          border-bottom: 2px solid #999;
        }
      }
    }
    .midBot {
      margin: 0 auto;
      width: 351px;
      border-radius: 10px;
      height: 280px;
      background-image: url(../../assets/greenBg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 0 15px;
      box-sizing: border-box;
      .case {
        display: flex;
        justify-content: space-between;
        font-size: 17px;
        color: #333;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .reduce {
        padding-top: 50px;
        margin-bottom: 33px;
        font-size: 20px;
        color: #333;
        font-weight: 700;
        text-align: center;
      }
    }
  }
  .bottom {
    height: 65px;
    padding: 0px 116px;
    display: flex;
    justify-content: space-between;
    position: relative;
    p {
      color: #999999;
      font-size: 10px;
      margin-top: 12px;
    }
    .leftLine {
      width: 35px;
      height: 8px;
      border-bottom: 1px solid #999999;
      margin-top: 12px;
    }
    .rightLine {
      width: 35px;
      height: 8px;
      border-bottom: 1px solid #999999;
      margin-top: 12px;
    }
  }
  /deep/.el-dialog {
    border-radius: 15px;
    width: 310px;
    height: 210px;
    .dialogTitle {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }
  }
}

/* 去掉input的上下小箭头 */

.clear-number-input.el-input::-webkit-outer-spin-button,
.clear-number-input.el-input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

.clear-number-input.el-input input[type='number']::-webkit-outer-spin-button,
.clear-number-input.el-input input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

.clear-number-input.el-input {
  -moz-appearance: textfield;
}

.clear-number-input.el-input input[type='number'] {
  -moz-appearance: textfield;
}

/deep/ .el-input__inner {
  border-color: #00c14a;
  padding: 0 2px;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>