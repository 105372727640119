import { render, staticRenderFns } from "./we.vue?vue&type=template&id=5cbec0bc&scoped=true"
import script from "./we.vue?vue&type=script&lang=js"
export * from "./we.vue?vue&type=script&lang=js"
import style0 from "./we.vue?vue&type=style&index=0&id=5cbec0bc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cbec0bc",
  null
  
)

export default component.exports